export default [{
  name: 'kegiatan-detail',
  path: '/kegiatan/:id(\\d+)+',
  component: () => import('@/views/kegiatan/DetailKegiatan.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Detail Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-baru',
  path: '/kegiatan/:id(\\d+)+/baru',
  component: () => import('@/views/kegiatan/TambahKegiatan.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Tambah Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-baru-item',
  path: '/kegiatan/:id(\\d+)+/baru/:jenis(\\d+)+',
  component: () => import('@/views/kegiatan/TambahKegiatanItem.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Tambah Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-review',
  path: '/kegiatan/:id(\\d+)+/review',
  component: () => import('@/views/kegiatan/ReviewKegiatan.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Detail Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Kegiatan',
      },
      {
        text: 'Review',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-ubah',
  path: '/kegiatan/:id(\\d+)+/ubah',
  component: () => import('@/views/kegiatan/UbahKegiatan.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Ubah Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-ubah-item',
  path: '/kegiatan/:id(\\d+)+/edit',
  component: () => import('@/views/kegiatan/UbahKegiatanItem.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Ubah Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-verifikasi',
  path: '/verifikasi/:id(\\d+)+',
  component: () => import('@/views/verifikasi-teknis/DetailVerifikasi.vue'),
  meta: {
    navActiveLink: 'verifikasi-teknis',
    pageTitle: 'Verifikasi Kegiatan',
    breadcrumb: [
      {
        text: 'Verifikasi Teknis',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-rekomendasi',
  path: '/rekomendasi/:id(\\d+)+',
  component: () => import('@/views/rekomendasi/DetailRekomendasi.vue'),
  meta: {
    navActiveLink: 'rekomendasi-awal',
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'rekomendasi-aplikasi',
  path: '/rekomendasi/:utama(\\d+)+/aplikasi/:id(\\d+)+/:type',
  component: () => import('@/views/rekomendasi/DetailRekomendasiAplikasi.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Aplikasi',
        active: true,
      },
    ],
  },
},
{
  name: 'rekomendasi-infrastruktur',
  path: '/rekomendasi/:utama(\\d+)+/infrastruktur/:id(\\d+)+/:type',
  component: () => import('@/views/rekomendasi/DetailRekomendasiInfrastruktur.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Infrastruktur',
        active: true,
      },
    ],
  },
},
{
  name: 'rekomendasi-item',
  path: '/rekomendasi/:utama(\\d+)+/item/:id(\\d+)+/:type/:data(\\d+)',
  component: () => import('@/views/rekomendasi/DetailRekomendasiItem.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Konfirmasi Rekomendasi Sementara',
        active: true,
      },
    ],
  },
},
{
  name: 'konfirmasi-aplikasi',
  path: '/konfirmasi/:utama(\\d+)+/aplikasi/:id(\\d+)+/:type',
  component: () => import('@/views/rekomendasi/DetailRekomendasiAplikasi.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Aplikasi',
        active: true,
      },
    ],
  },
},
{
  name: 'konfirmasi-infrastruktur',
  path: '/konfirmasi/:utama(\\d+)+/infrastruktur/:id(\\d+)+/:type',
  component: () => import('@/views/rekomendasi/DetailRekomendasiInfrastruktur.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Infrastruktur',
        active: true,
      },
    ],
  },
},
{
  name: 'konfirmasi-item',
  path: '/konfirmasi/:utama(\\d+)+/item/:id(\\d+)+/:type',
  component: () => import('@/views/rekomendasi/DetailRekomendasiItem.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
      },
      {
        text: 'Konfirmasi Rekomendasi Sementara',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-konfirmasi',
  path: '/konfirmasi/:utama(\\d+)+/:id(\\d+)+/:type',
  component: () => import('@/views/rekomendasi/DetailKonfirmasiRekomendasi.vue'),
  meta: {
    navActiveLink: 'konfirmasi-rekomendasi',
    pageTitle: 'Konfirmasi',
    breadcrumb: [
      {
        text: 'Konfirmasi',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'buat-surat-rekomendasi',
  path: '/surat/rekomendasi/:id(\\d+)+/add',
  component: () => import('@/views/rekomendasi/SuratRekomendasi.vue'),
  meta: {
    navActiveLink: 'surat-rekomendasi',
    pageTitle: 'Surat Rekomendasi',
    breadcrumb: [
      {
        text: 'Surat Rekomendasi',
        active: true,
      },
    ],
  },
},
{
  name: 'buat-berita-acara-rekomendasi',
  path: '/berita-acara/:id(\\d+)+/add/:jenis(\\d+)+',
  component: () => import('@/views/rekomendasi/BeritaAcaraKonfirmasi.vue'),
  meta: {
    navActiveLink: 'berita-acara',
    pageTitle: 'Berita Acara Konfirmasi',
    breadcrumb: [
      {
        text: 'Buat Berita Acara Konfirmasi',
        active: true,
      },
    ],
  },
},
{
  name: 'list-surat-rekomendasi',
  path: '/surat/rekomendasi/:id(\\d+)+/view',
  component: () => import('@/views/rekomendasi/DaftarSuratRekomendasi.vue'),
  meta: {
    navActiveLink: 'surat-rekomendasi',
    pageTitle: 'Surat Rekomendasi',
    breadcrumb: [
      {
        text: 'Surat Rekomendasi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-berita-acara',
  path: '/pengajuan/berita-acara',
  component: () => import('@/views/pengajuan/DaftarBeritaAcara.vue'),
  admin: false,
  notes: 'berita_acara',
  meta: {
    pageTitle: 'Daftar Berita Acara',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Berita Acara',
        active: true,
      },
    ],
  },
},
{
  name: 'kegiatan-baru-krisna',
  path: '/kegiatan/:id(\\d+)+/krisna/:jenis(\\d+)+',
  component: () => import('@/views/kegiatan/TambahKegiatanKrisna.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Tambah Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-efisiensi',
  path: '/pengajuan/efisiensi',
  component: () => import('@/views/pengajuan/DaftarEfisiensiAnggaran.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Form Efisiensi Anggaran',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Efisiensi Anggaran',
      },
      {
        text: 'Form Efisiensi',
        active: true,
      },
    ],
  },
},
]
