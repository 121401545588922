export default [{
  path: '/dashboard',
  name: 'dashboard',
  component: () => import('@/views/dashboard/Evaluasi.vue'),
},
{
  name: 'pengajuan-instansi',
  path: '/instansi',
  component: () => import('@/views/pengajuan/DaftarInstansi.vue'),
  meta: {
    navActiveLink: 'pengajuan-instansi',
    pageTitle: 'Daftar Instansi',
    breadcrumb: [
      {
        text: 'Instansi',
        to: '/instansi',
      },
      {
        text: 'Daftar Instansi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-instansi-detail',
  path: '/instansi/:id(\\d+)+',
  component: () => import('@/views/pengajuan/DaftarKegiatanInstansi.vue'),
  meta: {
    navActiveLink: 'pengajuan-instansi',
    pageTitle: 'Daftar Permohonan Instansi',
    breadcrumb: [
      {
        text: 'Instansi',
        to: '/instansi',
      },
      {
        text: 'Daftar Permohonan Instansi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan',
  path: '/pengajuan',
  component: () => import('@/views/platform/Pengajuan.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Daftar Permohonan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-surat',
  path: '/pengajuan/surat',
  component: () => import('@/views/surat/DaftarSurat.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Surat Permohonan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-surat-baru',
  path: '/surat/baru',
  component: () => import('@/views/surat/TambahSurat.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Tambah Surat Permohonan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
      },
      {
        text: 'Tambah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-surat-ubah',
  path: '/surat/:id(\\d+)+/ubah',
  component: () => import('@/views/surat/UbahSurat.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Daftar Surat Permohonan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
      },
      {
        text: 'Ubah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-surat-detail',
  path: '/surat/:id(\\d+)+',
  component: () => import('@/views/pengajuan/DaftarKegiatanSurat.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Daftar Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Permohonan',
      },
      {
        text: 'Daftar Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-review-kegiatan',
  path: '/pengajuan-review-kegiatan',
  component: () => import('@/views/kegiatan/DetailKegiatan.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Daftar Kegiatan',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Review Kegiatan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-rekomendasi-sementara',
  path: '/pengajuan/rekomendasi-sementara',
  component: () => import('@/views/pengajuan/DaftarRekomendasiSementara.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Daftar Rekomendasi Sementara',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Rekomendasi Sementara',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-konfimasi-rekomendasi-sementara',
  path: '/pengajuan/konfimasi-rekomendasi-sementara',
  component: () => import('@/views/pengajuan/DaftarKonfirmasiRekomendasiSementara.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Konfirmasi Pembahasan Rekomendasi Sementara',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Rekomendasi Sementara',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-surat-rekomendasi',
  path: '/pengajuan/surat-rekomendasi',
  component: () => import('@/views/pengajuan/DaftarSuratRekomendasi.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Daftar Surat Rekomendasi',
    breadcrumb: [
      {
        text: 'Pengajuan',
        to: '/pengajuan/surat',
      },
      {
        text: 'Surat Rekomendasi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-master-program',
  path: '/pengajuan/master/program',
  component: () => import('@/views/pengajuan/master/MasterProgram.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Program / Proses Bisnis',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Program',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-ubah-program',
  path: '/pengajuan/program/:id(\\d+)+/ubah',
  component: () => import('@/views/pengajuan/master/UbahProgram.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Program',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Program',
      },
      {
        text: 'Ubah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-program-baru',
  path: '/pengajuan/program/baru',
  component: () => import('@/views/pengajuan/master/TambahProgram.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Program',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Proses Bisnis',
      },
      {
        text: 'Tambah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-master-data-informasi',
  path: '/pengajuan/master/data-informasi',
  component: () => import('@/views/pengajuan/master/MasterDataInformasi.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Data dan Informasi',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Data dan Informasi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-ubah-data-informasi',
  path: '/pengajuan/data-informasi/:id(\\d+)+/ubah',
  component: () => import('@/views/pengajuan/master/UbahData.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Data dan Informasi',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Data dan Informasi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-data-informasi-baru',
  path: '/pengajuan/data-informasi/baru',
  component: () => import('@/views/pengajuan/master/TambahData.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Program',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Data dan Informasi',
      },
      {
        text: 'Tambah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-master-layanan',
  path: '/pengajuan/master/layanan',
  component: () => import('@/views/pengajuan/master/MasterLayanan.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Layanan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Layanan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-ubah-layanan',
  path: '/pengajuan/layanan/:id(\\d+)+/ubah',
  component: () => import('@/views/pengajuan/master/UbahLayanan.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Layanan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Layanan',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-layanan-baru',
  path: '/pengajuan/layanan/baru',
  component: () => import('@/views/pengajuan/master/TambahLayanan.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Program',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Layanan',
      },
      {
        text: 'Tambah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-master-kontak',
  path: '/pengajuan/master/kontak',
  component: () => import('@/views/pengajuan/master/MasterContact.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Penanggung Jawab',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Penanggung Jawab',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-tambah-kontak',
  path: '/pengajuan/kontak/baru',
  component: () => import('@/views/pengajuan/master/TambahContact.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Layanan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Penanggung Jawab',
      },
      {
        text: 'Tambah',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-ubah-kontak',
  path: '/pengajuan/kontak/:id(\\d+)+/ubah',
  component: () => import('@/views/pengajuan/master/UbahContact.vue'),
  notes: 0,
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Layanan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Penanggung Jawab',
      },
      {
        text: 'Ubah',
        active: true,
      },
    ],
  },
},
{
  name: 'verifikasi-teknis',
  path: '/verifikasi-teknis',
  component: () => import('@/views/platform/VerifikasiTeknis.vue'),
  meta: {
    pageTitle: 'Verifikasi Teknis',
    breadcrumb: [
      {
        text: 'Verifikasi Teknis',
        active: true,
      },
    ],
  },
},
{
  name: 'rekomendasi-awal',
  path: '/rekomendasi-awal',
  component: () => import('@/views/platform/RekomendasiAwal.vue'),
  meta: {
    pageTitle: 'Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekomendasi',
        active: true,
      },
    ],
  },
},
{
  name: 'rekap-rekomendasi-awal',
  path: '/rekap-rekomendasi-awal',
  component: () => import('@/views/platform/RekapRekomendasiAwal.vue'),
  meta: {
    pageTitle: 'Rekapitulasi Rekomendasi',
    breadcrumb: [
      {
        text: 'Rekapitulasi Rekomendasi Saya',
        active: true,
      },
    ],
  },
},
{
  name: 'persetujuan-direktur',
  path: '/persetujuan-eselon2',
  component: () => import('@/views/platform/PersetujuanDirektur.vue'),
  meta: {
    pageTitle: 'Persetujuan Direktur',
    breadcrumb: [
      {
        text: 'Persetujuan Direktur',
        active: true,
      },
    ],
  },
},
{
  name: 'rekapitulasi',
  path: '/rekapitulasi',
  component: () => import('@/views/platform/Rekapitulasi.vue'),
  meta: {
    pageTitle: 'Rekapitulasi',
    breadcrumb: [
      {
        text: 'Rekapitulasi',
        active: true,
      },
    ],
  },
},
{
  name: 'pembahasan-dispute',
  path: '/pembahasan-dispute',
  component: () => import('@/views/platform/PembahasanDispute.vue'),
  meta: {
    pageTitle: 'Pembahasan Dispute',
    breadcrumb: [
      {
        text: 'Pembahasan Dispute',
        active: true,
      },
    ],
  },
},
{
  name: 'konfirmasi-rekomendasi',
  path: '/konfirmasi-rekomendasi',
  component: () => import('@/views/platform/KonfirmasiRekomendasi.vue'),
  meta: {
    pageTitle: 'Konfirmasi',
    breadcrumb: [
      {
        text: 'Konfirmasi',
        active: true,
      },
    ],
  },
},
{
  name: 'berita-acara-konfirmasi',
  path: '/berita-acara',
  component: () => import('@/views/platform/BeritaAcaraKonfirmasi.vue'),
  meta: {
    pageTitle: 'Berita Acara Konfirmasi',
    breadcrumb: [
      {
        text: 'Berita Acara Konfirmasi',
        active: true,
      },
    ],
  },
},
{
  name: 'persetujuan-dirjen',
  path: '/persetujuan-eselon1',
  component: () => import('@/views/platform/PersetujuanDirjen.vue'),
  meta: {
    pageTitle: 'Persetujuan Dirjen',
    breadcrumb: [
      {
        text: 'Persetujuan Dirjen',
        active: true,
      },
    ],
  },
},
{
  name: 'rekapitulasi-dirjen',
  path: '/rekapitulasi-eselon1',
  component: () => import('@/views/platform/RekapitulasiDirjen.vue'),
  meta: {
    pageTitle: 'Rekapitulasi Eselon 1',
    breadcrumb: [
      {
        text: 'Rekapitulasi Eselon 1',
        active: true,
      },
    ],
  },
},
{
  name: 'penerbitan-surat',
  path: '/penerbitan-surat',
  component: () => import('@/views/platform/PenyusunanSurat.vue'),
  meta: {
    pageTitle: 'Penerbitan Surat',
    breadcrumb: [
      {
        text: 'Penerbitan Surat',
        active: true,
      },
    ],
  },
},
{
  name: 'surat-rekomendasi',
  path: '/surat/rekomendasi',
  component: () => import('@/views/platform/SuratRekomendasi.vue'),
  meta: {
    pageTitle: 'Surat Rekomendasi',
    breadcrumb: [
      {
        text: 'Surat Rekomendasi',
        active: true,
      },
    ],
  },
},
{
  name: 'daftar-antrian',
  path: '/daftar-antrian',
  component: () => import('@/views/platform/DaftarAntrian.vue'),
  meta: {
    pageTitle: 'Antrian',
    breadcrumb: [
      {
        text: 'Antrian',
        active: true,
      },
    ],
  },
},
{
  name: 'daftar-efisiensi',
  path: '/daftar-efisiensi',
  component: () => import('@/views/platform/DaftarEfisiensi.vue'),
  meta: {
    pageTitle: 'Efisiensi Anggaran',
    breadcrumb: [
      {
        text: 'Efisiensi Anggaran',
        active: true,
      },
    ],
  },
},
{
  name: 'log-sistem',
  path: '/log/sistem',
  component: () => import('@/views/platform/LogSistem.vue'),
  meta: {
    pageTitle: 'BSrE Log',
    breadcrumb: [
      {
        text: 'BSrE Log',
        active: true,
      },
    ],
  },
}]
